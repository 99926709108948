import { LABELS_TRANSLATION } from './settings'

export const getTranslatedLabel = (label, lang) => {
  const labelTranslations = LABELS_TRANSLATION[label]
  const translation = labelTranslations ? labelTranslations[lang] : false
  if (translation && lang) return translation
  return label
}

export const scrollToElementWithOffset = (element, margin) => {
  const rect = element.getBoundingClientRect()
  const scrollTop = document.documentElement.scrollTop
  window.scrollTo(window.scrollX, rect.top + scrollTop - margin)
}

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export const parseSearchQuery = () => {
  try {
    const search = location.search.substring(1)
    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/%3A/g, ':').replace(/0%3D/g, '') + '"}')
  } catch (err) {
    return {}
  }
}
