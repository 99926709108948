<template>
  <div class='donate-external-form' :class="isMobile ? 'mobile-form' : 'desktop-form'">
    <div class='Form__wrapper thanks-page' v-if="isThanksPage">
      <div :class="formClass">
        <Thanks @reset="resetForm" />
      </div>
    </div>
    <div class='Form__wrapper error-page' v-else-if="isErrorPage">
      <div :class="formClass">
        <Error @reset="resetForm" />
      </div>
    </div>
    <div class='Form__wrapper unsubscribe-page' v-else-if="isUnsubscribePage">
      <div :class="formClass">
        <UnsubscribeForm @reset="resetForm" />
      </div>
    </div>
    <div class='Form__wrapper settings-page' v-else-if="isSettingsPage">
      <div :class="formClass">
        <SubscriptionSettingsForm @reset="resetForm" />
      </div>
    </div>
    <div class='Form__wrapper redirect-step' v-else-if="isRedirectStep">
      <div :class="formClass">
        <RedirectStep />
      </div>
    </div>
    <div :class="'Form__wrapper donate-page form-lang-' + lang + (haveContext || isHidden('project') ? ' form-with-context' : '')" v-else>
      <div class="BigForm__wrapper" v-if="form === 'big'">
        <OperatorCallStep v-if='isOperatorCallStep' @cancel="resetForm" />
        <CurrencySelector v-if='currencies.length > 1' />
        <div class="BigForm__main">
          <MethodSwitcher v-if="!isHidden('method')" />
          <div v-if="!hiddenForm()">
            <SumSelector v-if="!isHidden('sum')" />
            <MonthSwitcher v-if="!isHidden('month') && activeMethod !== 'rb-sbp'" />
            <DonorDataForm />
            <div class="BigForm__form form-footer">
              <DropDown :values="projects" :value="project" :disabled="month" @selected="changeProject" :label="getTranslatedLabel('purpose', lang)"  v-if="!isHidden('project') && !haveContext" />
              <Agreement v-if="!isHidden('agreement')" />
              <FormControls ref='controls' />
              <ExternalFooter />
            </div>
          </div>
          <div v-else>
            <ExternalOperatorForm :method="activeMethod" />
          </div>
        </div>
      </div>
      <div class="MainPageForm__root" v-else-if="form === 'main'" :class="centered ? 'form-centered' : ''">
        <OperatorCallStep v-if='isOperatorCallStep' @cancel="resetForm" />
        <MonthSwitcher v-if="!isHidden('month') && activeMethod !== 'rb-sbp'" />
        <div class="MainPageForm__form">
          <SumSelector v-if="!isHidden('sum')" />
          <DonorDataForm />
          <Agreement v-if="!isHidden('agreement')" />
          <FormControls ref='controls' />
        </div>
      </div>
      <div class="SmallForm__root" v-else-if="form === 'small'" :class="centered ? 'form-centered' : ''">
        <OperatorCallStep v-if='isOperatorCallStep' @cancel="resetForm" />
        <div class="SmallForm__wrapper">
          <MonthSwitcher v-if="!isHidden('month') && activeMethod !== 'rb-sbp'" />
          <div class="SmallForm__form">
            <SumSelector v-if="!isHidden('sum')" />
            <DonorDataForm />
            <FormControls ref='controls' />
            <Agreement v-if="!isHidden('agreement')" />
          </div>
        </div>
      </div>
      <div class="SmallForm__root" v-else-if="form === 'custom'" :class="centered ? 'form-centered' : ''">
        <OperatorCallStep v-if='isOperatorCallStep' @cancel="resetForm" />
        <div class="SmallForm__wrapper">
          <MonthSwitcher v-if="!isHidden('month') && activeMethod !== 'rb-sbp'" />
          <MethodSwitcher v-if="!isHidden('method')" />
          <div class="SmallForm__form">
            <SumSelector v-if="!isHidden('sum')" />
            <DonorDataForm />
            <DropDown :values="projects" :value="project" :disabled="month" @selected="changeProject" :label="getTranslatedLabel('purpose', lang)"  v-if="!isHidden('project') && !haveContext" />
            <Agreement v-if="!isHidden('agreement')" />
            <FormControls ref='controls' />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Thanks from '../embed/Thanks.vue'
import Error from '../embed/Error.vue'
import UnsubscribeForm from '../embed/UnsubscribeForm.vue'
import SubscriptionSettingsForm from '../embed/SubscriptionSettingsForm.vue'
import RedirectStep from '../embed/RedirectStep.vue'
import OperatorCallStep from '../OperatorCallStep.vue'
import CurrencySelector from '../embed/CurrencySelector.vue'
import MethodSwitcher from '../embed/MethodSwitcher.vue'
import SumSelector from '../embed/SumSelector.vue'
import MonthSwitcher from '../embed/MonthSwitcher.vue'
import DropDown from '../embed/DropDown.vue'
import DonorDataForm from '../embed/DonorDataForm.vue'
import Agreement from '../embed/Agreement.vue'
import FormControls from '../embed/FormControls.vue'
import ExternalOperatorForm from './ExternalOperatorForm'
import ExternalFooter from './ExternalFooter'
import { getTranslatedLabel } from '../../helpers'

export default {
  props: ['isMobile'],
  components: {
    Thanks, Error, UnsubscribeForm, SubscriptionSettingsForm, RedirectStep, OperatorCallStep, CurrencySelector, MethodSwitcher, SumSelector, MonthSwitcher, DropDown, DonorDataForm, Agreement, FormControls, ExternalOperatorForm, ExternalFooter
  },
  data: () => ({
    currentHeight: 0
  }),
  computed: mapState({
    step: state => state.step,
    projects: state => state.projects,
    project: state => state.donation.project,
    month: state => state.donation.month,
    activeMethod: state => state.donation.method,
    activeOperator: state => state.operators[state.donation.method],
    operatorGroups: state => state.groups,
    haveContext: state => state.donation.child.length > 0,
    form: state => state.formType,
    hiddenFields: state => state.hiddenFields,
    currencies: state => Object.keys(state.currencies),
    centered: state => state.centeredForm,
    isThanksPage: state => state.step === 10,
    isErrorPage: state => state.step === 20,
    isUnsubscribePage: state => state.step === 30,
    isSettingsPage: state => state.step === 40,
    isRedirectStep: state => state.step === 3,
    isOperatorCallStep: state => state.step === 4,
    lang: state => state.lang,
    formClass: state => state.formType === 'big' ? 'BigForm__wrapper' : state.formType === 'main' ? 'MainPageForm__root' : 'SmallForm__root'
  }),
  methods: {
    isHidden: function (fieldName) {
      return this.hiddenFields.indexOf(fieldName) > -1
    },
    hiddenForm: function () {
      const currentOperator = this.activeOperator
      const currentGroup = this.operatorGroups[currentOperator.group]
      if (currentGroup.hideForm === true || currentOperator.hideForm === true) return true
      return false
    },
    resetForm () {
      this.$store.dispatch('resetForm')
      if (this.$refs.controls) {
        this.$refs.controls.unblock()
      }
    },
    changeProject (value) {
      this.$store.commit('updateDonation', { prop: 'project', value })
    },
    showSuccessPage: function () {
      this.$store.dispatch('thanksPage')
    },
    showErrorPage: function () {
      this.$store.dispatch('errorPage')
    },
    getTranslatedLabel
  },
  mounted () {
    this.$store.dispatch('identifyDonor')
  }
}
</script>

<style scoped>
.thanks-page > *,
.error-page > * {
  padding: 20px 0;
}
.form-lang-en .BigForm__offer_label,
.form-with-context .BigForm__offer_label {
  border: none;
  padding-top: 10px;
}
.form-footer {
  margin-top: 14px;
}
.SmallForm__offer {
  margin-top: 10px;
}
.mobile-form .SmallForm__offer {
  margin-top: 0px;
}
.donate-external-form .BigForm__form .hint {
  position: relative;
  top: -20px;
  border-bottom: 1px dashed;
  font-size: 13px;
}
@media screen and (min-width: 1920px) {
  .donate-external-form .BigForm__form .hint {
    top: -35px;
  }
}
@media screen and (max-width: 767px) {
  .form-footer {
    margin-top: 0px;
  }
  .thanks-page > *,
  .error-page > * {
    padding: 20px;
  }
}
</style>
