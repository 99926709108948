export const STATS = {
  'year': '2021',
  'year_sum': '526 377 460',
  'month': 'апрель',
  'month_sum': '142 612 633'
}

export const PROJECTS = {
  0: 'На уставную деятельность',
  10: 'Адресная помощь',
  35342: 'Помощь детям в клиниках'
}

export const CONFIGURABLE_PROPS = {
  'currency': 'currencyGetter',
  'fields': 'fields',
  'groups': 'groupsGetter',
  'sum': 'sumGetter',
  'month': 'donation.month',
  'email': 'donation.email',
  'pageSource': 'donation.pageSource',
  'source': 'donation.source',
  'project': 'donation.project',
  'thanksText': 'thanksText',
  'errorText': 'errorText',
  'form': 'formType',
  'lang': 'lang',
  'dev': 'dev'
}

export const CONFIGURABLE_PROPS_V2 = {
  'currency': 'currencyDesktop',
  'currencyMobile': 'currencyMobile',
  'currencySettings': 'currencyGetter',
  'fields': 'fields',
  'hiddenFields': 'hiddenFields',
  'groups': 'groupsGetter',
  'sum': 'sumDesktop',
  'sumMobile': 'sumMobile',
  'month': 'donation.month',
  'email': 'donation.email',
  'pageSource': 'donation.pageSource',
  'source': 'donation.source',
  'project': 'donation.project',
  'thanksText': 'thanksText',
  'errorText': 'errorText',
  'actionText': 'actionText',
  'actionApayText': 'actionApayText',
  'form': 'formType',
  'lang': 'lang',
  'dev': 'dev'
}

export const DEFAULT_FORMTYPE = 'big'

export const CURRENCIES = () => ({
  'RUB': {
    label: 'Рубли',
    scale: [100, 500, 1000, 5000, 10000],
    symbol: '₽'
  },
  'USD': {
    label: 'Доллары',
    scale: [5, 15, 30, 50, 100],
    symbol: '$'
  },
  'EUR': {
    label: 'Евро',
    scale: [5, 15, 30, 50, 100],
    symbol: '€'
  }
})

export const MOBILE_CURRENCIES = () => ({
  'RUB': {
    scale: [700, 1500],
    symbol: 'Р'
  },
  'USD': {
    scale: [30, 100],
    symbol: '$'
  },
  'EUR': {
    scale: [30, 100],
    symbol: '€'
  }
})

export const GET_CURRENCIES = mobile => {
  return mobile ? MOBILE_CURRENCIES() : CURRENCIES()
}

export const EMBED_CURRENCIES = () => ({
  'RUB': {
    label: 'Рубли',
    scale: [700, 1200, 3000],
    symbol: '₽'
  },
  'USD': {
    label: 'Доллары',
    scale: [15, 50, 100],
    symbol: '$'
  },
  'EUR': {
    label: 'Евро',
    scale: [15, 50, 100],
    symbol: '€'
  }
})

export const EMBED_MOBILE_CURRENCIES = () => ({
  'RUB': {
    scale: [700, 1200, 3000],
    symbol: 'Р'
  },
  'USD': {
    scale: [30, 50, 100],
    symbol: '$'
  },
  'EUR': {
    scale: [30, 50, 100],
    symbol: '€'
  }
})

export const EMBED_CURRENCIES_V2 = () => ({
  'RUB': {
    scale: [800, 1200, 3000],
    symbol: '₽'
  },
  'USD': {
    scale: [30, 50, 100],
    symbol: '$'
  },
  'EUR': {
    scale: [30, 50, 100],
    symbol: '€'
  }
})

export const PROMO2023_EMBED_CURRENCIES = () => ({
  'RUB': {
    scale: [10000, 30000, 50000],
    symbol: '₽'
  },
  force: true
})

export const GET_EMBED_CURRENCIES = mobile => {
  return mobile ? EMBED_MOBILE_CURRENCIES() : EMBED_CURRENCIES()
}

export const DEFAULT_SUM = 1000

export const DEFAULT_MOBILE_SUM = 700

export const DEFAULT_SUM_GETTER = mobile => {
  return mobile ? DEFAULT_MOBILE_SUM : DEFAULT_SUM
}

export const DEFAULT_SUM_GETTER_V2 = () => {
  return 500
}

export const QR_IMAGE = require('./assets/embeded/qr-code.png')
export const QR_LINK = 'https://qr.nspk.ru/AS10007A5IFMFOD18KLPAVU3IHC9154G?type=01&bank=100000000012&crc=C844'

export const QR_CONFIG = {
  image: QR_IMAGE,
  link: QR_LINK
}
export const PROMO2023_QR_IMAGE = require('./assets/embeded/promo2023-qr-code.png')
export const PROMO2023_QR_LINK = 'https://qr.nspk.ru/BS1A000VSAFADFFD8K39G189SN1E8HBG?type=01&bank=100000000012&crc=9CAC'

export const PROMO2023_QR_CONFIG = {
  image: PROMO2023_QR_IMAGE,
  link: PROMO2023_QR_LINK
}

export const PRESETS = {
  'v5': {
    'currencyGetter': EMBED_CURRENCIES_V2,
    'currencies': EMBED_CURRENCIES_V2(),
    'sumGetter': DEFAULT_SUM_GETTER_V2
  },
  'promo2023': {
    'currencyGetter': PROMO2023_EMBED_CURRENCIES,
    'currencies': PROMO2023_EMBED_CURRENCIES(),
    'qr': PROMO2023_QR_CONFIG,
    'hiddenFields': ['month', 'settings']
  }
}

export const OPERATORS = {
  'cp': {
    'name': 'CloudPayments',
    'group': 'cards',
    'month': true,
    'currencies': ['RUB']
  },
  'cards-external': {
    'name': 'Cards External',
    'group': 'cards',
    'month': true,
    'hideForm': true,
    'currencies': ['USD', 'EUR']
  },
  // 'un-cards': {
  //   'name': 'Uniteller',
  //   'group': 'cards',
  //   'month': true,
  //   'currencies': ['RUB']
  // },
  // 'assist-cards': {
  //   'name': 'Assist',
  //   'group': 'cards',
  //   'month': false,
  //   // 'currencies': ['RUB']
  //   'currencies': ['RUB', 'USD', 'EUR']
  // },
  'cp-ap': {
    'name': 'CloudPayments Apple Pay',
    'group': 'apay',
    'month': true,
    'currencies': ['RUB']
  },
  'cp-yp': {
    'name': 'CloudPayments Yandex Pay',
    'group': 'yandexpay',
    'month': true,
    'currencies': ['RUB']
  },
  'ym': {
    'name': 'ЮMoney',
    'group': 'yandexmoney',
    'month': false,
    'currencies': ['RUB']
  },
  'yk-sp': {
    'name': 'YooKassa SberPay',
    'group': 'sberpay',
    'month': false,
    'currencies': ['RUB']
  },
  // 'pm-wm': {
  //   'name': 'WebMoney',
  //   'group': 'webmoney',
  //   'month': false,
  //   'currencies': ['RUB']
  // },
  // 'pp-cards': {
  //   'name': 'PayPal',
  //   'group': 'paypal',
  //   'month': true,
  //   'currencies': ['RUB', 'USD', 'EUR']
  // },
  'pp-cards-external': {
    'name': 'PayPal',
    'group': 'paypal-external',
    'month': true,
    'currencies': ['USD', 'EUR']
  },
  // 'qr-code': {
  //   'name': 'QR код',
  //   'group': 'qr-code',
  //   'month': false,
  //   'currencies': ['RUB']
  // },
  'rb-sbp': {
    'name': 'QR код',
    'group': 'rb-sbp',
    'month': false,
    'currencies': ['RUB']
  } // ,
  // 'sb-buiseness': {
  //   'name': 'SberBusiness',
  //   'currencies': ['RUB'],
  //   'invisible': true
  // }
}

export const OPERATOR_GROUPS = (apay, mobile) => ({
  'cards': {
    'name': 'Банковские карты',
    'image': 'cards-label.png',
    'selected': 'cards-label-selected.png',
    'mobile_image': 'cards-mobile.png',
    'mobile_selected': 'cards-mobile-selected.png'
  },
  'apay': {
    'name': 'Apple Pay',
    'image': 'apay.png',
    'selected': 'apay-selected.png',
    'disabled': !apay
  },
  'yandexmoney': {
    'name': 'ЮMoney',
    'image': 'yandexmoney.png',
    'selected': 'yandexmoney-selected.png'
  },
  'sberpay': {
    'name': 'SberPay',
    'image': 'sbpay.png'
  },
  // 'yandexpay': {
  //   'name': 'Yandex Pay',
  //   'image': 'yapay.png',
  //   'dev': true
  // },
  // 'webmoney': {
  //   'name': 'WebMoney',
  //   'image': 'webmoney.png',
  //   'selected': 'webmoney-selected.png',
  //   'disabled': apay
  // },
  // 'paypal': {
  //   'name': 'PayPal',
  //   'image': 'paypal.png',
  //   'selected': 'paypal-selected.png'
  // },
  'paypal-external': {
    'name': 'PayPal',
    'image': 'paypal.png',
    'selected': 'paypal-selected.png',
    'hideForm': true
  },
  // 'qr-code': {
  //   'name': 'QR-код',
  //   'hideForm': true,
  //   'languages': ['ru']
  // },
  'rb-sbp': {
    'name': 'QR-код',
    'languages': ['ru']
  }
})

export const GET_DEFAULT_OPERATOR = (apay, formType) => {
  if (formType === 'small' || formType === 'main') return 'cp'
  return apay ? 'cp-ap' : 'cp'
}

export const OTHER_METHODS = {
  'sberbank': {
    'name': 'Через Сбербанк',
    'icon': 'sb.png',
    'methods': [{
      'name': 'Сбербанк - Квитанция',
      'link': 'https://old.podari-zhizn.ru/main/donate/sber'
    }, {
      'name': 'Сбербанк – VISA «Подари жизнь»',
      'link': 'https://old.podari-zhizn.ru/main/node/8889'
    }, {
      'name': 'Сбербанк – Вклад «Подари жизнь»',
      'link': 'http://www.sbrf.ru/moscow/ru/person/contributions/deposits/podari_zhizn'
    }, {
      'name': 'Сбербанк – Банкоматы',
      'link': 'https://old.podari-zhizn.ru/main/node/7421'
    }, {
      'name': 'Сбербанк – Мобильный банк',
      'link': 'https://old.podari-zhizn.ru/main/node/8890'
    }, {
      'name': 'Сбербанк ОнЛ@йн',
      'link': 'https://old.podari-zhizn.ru/main/node/8215'
    }, {
      'name': 'Сбербанк – Ежемесячное списание',
      'link': 'https://old.podari-zhizn.ru/main/node/7650'
    }]
  },
  'change': {
    'name': 'Поделись сдачей',
    'icon': 'coins.png',
    'link': 'https://old.podari-zhizn.ru/main/node/26954'
  },
  'cash': {
    'name': 'Наличными',
    'icon': 'cash.png',
    'methods': [{
      'name': 'Платежные терминалы',
      'link': 'https://old.podari-zhizn.ru/main/node/7419'
    }, {
      'name': 'В кассу Фонда',
      'link': 'https://old.podari-zhizn.ru/main/node/7651'
    }, {
      'name': 'Ящики для пожертвований',
      'link': 'https://old.podari-zhizn.ru/main/node/7417'
    }]
  },
  'cryptomoney': {
    'name': 'Криптовалюты',
    'icon': 'bc.png',
    'link': 'http://www.podari.life/btcru'
  },
  'mobile': {
    'name': 'Пожертвуй с телефона',
    'icon': 'mobile.png',
    'methods': [{
      'name': 'SMS на короткий номер 6162',
      'link': 'https://old.podari-zhizn.ru/main/donate/sms-6162',
      'icon': 'mobile_operators.png'
    }]
  },
  'qiwi': {
    'name': 'QIWI',
    'icon': 'qiwi.png',
    'methods': [{
      'name': 'QIWI Wallet',
      'link': 'https://old.podari-zhizn.ru/main/node/7418'
    }, {
      'name': 'Терминалы QIWI',
      'link': 'https://old.podari-zhizn.ru/main/node/16445'
    }]
  },
  'legal': {
    'name': 'Для компаний',
    'icon': 'legal.png',
    'link': 'https://old.podari-zhizn.ru/main/company',
    'mobileOnly': true
  },
  'foreign': {
    'name': 'Помочь за рубежом',
    'icon': 'globe.png',
    'methods': [{
      'name': 'Помочь в Великобритании',
      'link': 'http://giftoflife.eu',
      'icon': 'gol.png'
    }, {
      'name': 'Помочь в США',
      'link': 'http://www.podari.life',
      'icon': 'pl.png'
    }]
  },
  'other-banks': {
    'name': 'Другие банки',
    'icon': 'coins.png',
    'methods': [{
      'name': 'ВТБ',
      'link': 'https://online.vtb.ru/services/payment',
      'icon': 'vtb.png',
      'action': 'modalVTB'
    }, {
      'name': 'Санкт-Петербург',
      'link': 'https://i.bspb.ru/vendors/358065154043/358065154049',
      'icon': 'spb.png'
    }, {
      'name': 'Альфабанк',
      'link': 'https://click.alfabank.ru/login/?contextType=external&username=string&password=secure_string&challenge_url=https%3A%2F%2Fclick.alfabank.ru%3A443%2Flogin%2F&request_id=3964997340588272207&authn_try_count=0&locale=ru_RU&resource_url=https%253A%252F%252Fclick.alfabank.ru%252FALFAIBSR%252F',
      'icon': 'alfa.png'
    }]
  }
}

export const LABELS_TRANSLATION = {
  'rub': {
    'ru': 'Рубли',
    'en': 'Rubles'
  },
  'usd': {
    'ru': 'Доллары',
    'en': 'Dollars'
  },
  'eur': {
    'ru': 'Евро',
    'en': 'Euros'
  },
  'other-sum': {
    'ru': 'Другая сумма',
    'en': 'Other amount'
  },
  'other-sum-mobile': {
    'ru': 'Укажите сумму',
    'en': 'Choose you donation amount'
  },
  'agreement': {
    'ru': 'Принимаю <a href="https://api.podari-zhizn.ru/storage/content_block/document/4/regular_document-758f4099343a379e8fc5e5fd6a33865f.pdf" target="_blank">публичную оферту</a> и даю согласие на <a href="https://api.podari-zhizn.ru/storage/content_block/document/5/document-49b5f547dead80934a49190e51eddde0.pdf" target="_blank">обработку персональных данных</a>',
    'en': 'By clicking ’DONATE’ button, you agree to <a href="https://api.podari-zhizn.ru/storage/content_block/document/4/regular_document-758f4099343a379e8fc5e5fd6a33865f.pdf" target="_blank">Our terms of and conditions</a>'
  },
  'agreement-error': {
    'ru': '* пожалуйста, подтвердите ваше согласие на условия оферты и обработку персональных данных',
    'en': '* please confirm your agreement to the terms of the offer'
  },
  'lastname': {
    'ru': 'Фамилия*',
    'en': 'Last name*'
  },
  'firstname': {
    'ru': 'Имя',
    'en': 'First name'
  },
  'email': {
    'ru': 'Email*',
    'en': 'Email*'
  },
  'phone': {
    'ru': 'Телефон',
    'en': 'Phone'
  },
  'email-required-error': {
    'ru': '* укажите ваш e-mail',
    'en': '* enter your email address'
  },
  'email-format-error': {
    'ru': 'укажите корректный email',
    'en': 'enter a valid email address'
  },
  'email-suggestion': {
    'ru': 'Вы имели в виду',
    'en': 'Did you mean'
  },
  'lastname-required-error': {
    'ru': '* укажите вашу фамилию',
    'en': '* enter your last name'
  },
  'phone-format-error': {
    'ru': 'укажите номер телефона в формате 7ХХХХХХХ',
    'en': 'please provide your phone number in format 7ХХХХХХХ'
  },
  'email-symbols-error': {
    'ru': 'Поле не должно содержать кириллических символов',
    'en': 'The input should not contain cyrillic symbols'
  },
  'manage-subscription': {
    'ru': 'Управление подпиской',
    'en': 'Subscription management'
  },
  'donate-button': {
    'ru': 'Помочь',
    'en': 'Donate'
  },
  'donate-button-apay': {
    'ru': 'Помочь с',
    'en': 'Donate with'
  },
  'donate-button-wait': {
    'ru': 'Пожалуйста, подождите...',
    'en': 'Please wait...'
  },
  'one-time': {
    'ru': 'Разово',
    'en': 'One time'
  },
  'monthly': {
    'ru': 'Ежемесячно',
    'en': 'Monthly'
  },
  'purpose': {
    'ru': 'Назначение платежа',
    'en': 'Purpose of payment'
  },
  'method': {
    'ru': 'Выберите способ оплаты',
    'en': 'Select payment method'
  },
  'cards': {
    'ru': 'Банковские карты',
    'en': 'Debit/credit card'
  },
  'apay': {
    'ru': 'Apple Pay',
    'en': 'Apple Pay'
  },
  'yandexmoney': {
    'ru': 'ЮMoney',
    'en': 'Yandex Money'
  },
  'webmoney': {
    'ru': 'WebMoney',
    'en': 'WebMoney'
  },
  'paypal': {
    'ru': 'PayPal',
    'en': 'PayPal'
  },
  'paypal-external': {
    'ru': 'PayPal',
    'en': 'PayPal'
  },
  'sberpay': {
    'ru': 'SberPay',
    'en': 'SberPay'
  },
  'yandexpay': {
    'ru': 'Яндекс Pay',
    'en': 'Yandex Pay'
  },
  'qr-code': {
    'ru': 'QR код',
    'en': 'QR code'
  },
  'qr-code-intro': {
    'ru': '<p><b>Оплата по QR-коду с помощью Системы Быстрых Платежей</b></p><p>Кликните или отсканируйте QR-код, чтобы подтвердить оплату в мобильном приложении вашего банка</p>',
    'en': ''
  },
  'rb-sbp': {
    'ru': 'QR код',
    'en': 'QR code'
  },
  'paypal-external-intro-usd': {
    'ru': '<p>Поддержите детей в России, подопечных фонда «Подари жизнь», оформив пожертвование в сестринском американском фонде Podari.Life</p>',
    'en': '<p>To help children in Russia beat cancer donate to Podari.Life, the US sister chaity of Podari Zhizn.</p>'
  },
  'paypal-external-intro-eur': {
    'ru': '<p>Поддержите детей в России, подопечных фонда «Подари жизнь», оформив пожертвование в сестринском европейском фонде Gift of Life</p>',
    'en': '<p>To help children in Russia beat cancer donate to Gift of Life, Podari Zhizn UK sister charity.</p>'
  },
  'default-thanks': {
    'ru': '<p>Спасибо за доверие и помощь!</p><br /><p>Вы поддерживаете проекты фонда, и у детей появляется шанс лечиться не только по государственной программе. Задача фонда дополнить ее, сделать так, чтобы самые лучшие и эффективные методы терапии были доступны всем детям. Благодаря вам это становится возможным.</p>',
    'en': '<p>Thank you for your support and trust in us!</p><br /><p>With your contribution, we can provide children with all necessary support that cannot be guaranteed by the state funding. It is our priority to give all children and their families better access to the most effective medical treatment. Thank you for making it possible.</p>'
  },
  'bigform-thanks': {
    'ru': '<p>Спасибо за доверие и помощь!</p><br /><p>Вы помогаете детям выздоравливать и быть счастливыми! Победив болезнь, ребята возвращаются к обычной жизни: учатся, работают, создают семьи. Может ли что-то быть прекраснее? Мы благодарим вас за это.</p>',
    'en': '<p>Thank you for being by our side!</p><br /><p>You are helping children beat their illness and be happy again. By winning this battle, the children gain their normal life back: they study, pursue fulfilling careers, start their own families. Could there be anything more rewarding? And we can’t thank you enough for making it possible.</p>'
  },
  'default-error': {
    'ru': '<p>К сожалению произошла ошибка</p>',
    'en': '<p>Unfortunately an error occurred</p>'
  },
  'return-button': {
    'ru': 'Вернуться к форме',
    'en': 'Go Back'
  },
  'make-donation-form': {
    'ru': 'Сделать пожертвование',
    'en': 'Make a Donation'
  },
  'unsubscribe-button': {
    'ru': 'Отписаться',
    'en': 'Unsubscribe'
  },
  'change-settings-button': {
    'ru': 'Изменить настройки',
    'en': 'Change the Settings'
  },
  'change-settings-current-sum': {
    'ru': 'Текущая сумма подписки',
    'en': 'Current subscription sum'
  },
  'change-settings-new-sum': {
    'ru': 'Новая сумма',
    'en': 'New sum'
  },
  'settings-form-intro': {
    'ru': '<p>Чтобы изменить сумму ежемесячных списаний с банковской карты, пожалуйста, укажите свой e-mail, а также прежнюю и новую сумму подписки. Ссылка для подтверждения будет выслана вам на указанный адрес.</p><br /><p>Чтобы отменить подписку, перейдите по <a href="?unsubscribe=true">ссылке</a>.</p>',
    'en': '<p>To change the amount of monthly donation, please enter your e-mail, the existing and the new donation amounts. A confirmation link will be sent to your email address.</p><br /><p>To cancel your subscription, <a href="?unsubscribe=true">follow the link</a>.</p>'
  },
  'settings-form-sent': {
    'ru': '<p>На ваш адрес отправлено письмо. Чтобы завершить изменение настроек, перейдите по указанной в письме ссылке.</p>',
    'en': '<p>We\'ve sent you an email with a link you should follow</p>'
  },
  'settings-form-done': {
    'ru': '<p>Настройки успешно изменены!</p>',
    'en': '<p>You\'ve succeffully change subscription settings</p>'
  },
  'settings-form-error': {
    'ru': '<p>К сожалению мы не смогли найти пожертвования зарегистрированного на указанный e-mail. Если вам не удается изменить настройки, напишите нам по адресу <a href="mailto:donate@podari-zhizn.ru">donate@podari-zhizn.ru</a></p>',
    'en': '<p>Unfortenatelly we didn\'t found subscriptions registered to this email address. If you didn\'t successed with changing subscription settings, please write to <a href="mailto:donate@podari-zhizn.ru">donate@podari-zhizn.ru</a></p>'
  },
  'unsubscribe-form-intro': {
    'ru': '<p>Мы благодарны вам за помощь! Если вы хотите отказаться от ежемесячных пожертвований, укажите в форме ниже ваш e-mail. Мы пришлем ссылку для подтверждения.</p><br /><p>Спасибо!</p>',
    'en': '<p>Thank you for your support.</p><br /><p>If you would like to cancel your monthly donation, type in your email address in the form below. We will send you a confirmation link.</p><br /><p>Thank you!</p>'
  },
  'unsubscribe-form-sent': {
    'ru': '<p>На ваш e-mail отправлена ссылка, перейдите по ней, пожалуйста.</p>',
    'en': '<p>Please check your email for a confirmation link and click it to proceed.</p>'
  },
  'unsubscribe-form-done': {
    'ru': '<p>Спасибо, что были с нами!</p>',
    'en': '<p>Thank you for being with us!</p>'
  },
  'unsubscribe-form-error': {
    'ru': '<p>Нам не удалось найти ваш e-mail. Напишите нам, пожалуйста, на <a href="mailto:donate@podari-zhizn.ru">donate@podari-zhizn.ru</a></p>',
    'en': '<p>We cannot find your email address. Please email us at <a href="mailto:donate@podari-zhizn.ru">donate@podari-zhizn.ru</a></p>'
  }
}

export const EXPERIMENTAL_VERSIONS = {
  '234234234.01': 'no-comments'
}

export const DONATE_API = 'https://donate.podari-zhizn.ru/donate'

export const QR_STATUS_API = 'https://donate.podari-zhizn.ru/rb/sbp/qr'

export const CONFIG_API = 'https://donate.podari-zhizn.ru/api/public/config/client'

export const SUBSCRIPTION_SETTINGS_API = 'https://donate.podari-zhizn.ru/api/public/donations/changesum/request/'

export const UNSUBSCRIBE_API = 'https://donate.podari-zhizn.ru/api/public/donations/unsubscribe/request/'

export const APPLEPAY_API = 'https://donate.podari-zhizn.ru/api/donate/startApplePaySession'

export const MINDBOX_API = 'https://donate.podari-zhizn.ru/api/public/mindbox'
