<template>
  <div class="donate-external-form-wrapper">
    <ExternalForm :isMobile="isMobile" ref='form' />
  </div>
</template>

<script>
import { CONFIGURABLE_PROPS_V2 } from '../../settings'
import ExternalForm from './ExternalForm.vue'

export default {
  props: ['settings'],
  components: { ExternalForm },
  data: () => ({
    windowWidth: window.outerWidth
  }),
  computed: {
    isMobile: function () {
      return this.windowWidth <= 768
    }
  },
  methods: {
    modifySettings (settings) {
      let change = {}
      Object.keys(CONFIGURABLE_PROPS_V2).forEach(prop => {
        if (settings[prop]) {
          const path = CONFIGURABLE_PROPS_V2[prop].split('.')
          if (path.length === 2) {
            if (!change[path[0]]) change[path[0]] = {}
            change[path[0]][path[1]] = settings[prop]
          } else {
            change[path[0]] = settings[prop]
          }
        }
      })

      this.$store.dispatch('setConfiguration', change)
    },
    finishTransaction (success) {
      const successFn = this.settings.onSuccess
      const failFn = this.settings.onFail
      if (success) {
        if (this.$store.state.donation.page_source === 'PHD24' || this.$store.state.donation.pageSource === 'PHD24') {
          const lang = this.$store.state.lang
          if (lang === 'en') {
            window.location.href = `https://tickets.phdays.com/en/user/profile?sufficientAmount=${this.$store.state.donation.sum >= 1000}`
          } else {
            window.location.href = `https://tickets.phdays.com/user/profile?sufficientAmount=${this.$store.state.donation.sum >= 1000}`
          }
        } else if (this.$store.state.backUrl) {
          window.location.href = this.$store.state.backUrl
        } else if (successFn) {
          successFn.call()
        } else {
          this.$refs.form.showSuccessPage()
        }
      } else {
        if (failFn) {
          failFn.call()
        } else {
          this.$refs.form.showErrorPage()
        }
      }
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    this.$store.dispatch('switchDeviceVersion', { mobile: this.isMobile, version: 'external-v2' })
    this.modifySettings(this.settings)
  },
  watch: {
    isMobile: function (isMobile) {
      this.$store.dispatch('switchDeviceVersion', { mobile: isMobile, version: 'external-v2' })
    }
  }
}
</script>

<style>
  @import '../../assets/embeded/embeded.css';
</style>
