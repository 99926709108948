import request from './request'
import {
  APPLEPAY_API,
  CONFIG_API,
  DONATE_API,
  QR_STATUS_API,
  SUBSCRIPTION_SETTINGS_API,
  UNSUBSCRIBE_API
} from '../settings'

export default {
  donate (donation, cb) {
    request('POST', DONATE_API, donation, cb)
  },
  unsubscribe (email, newPage = false, cb) {
    request('POST', UNSUBSCRIBE_API + email, { new_page: newPage }, cb)
  },
  changeSettings (email, currentSum, sum, newPage = false, cb) {
    request('POST', SUBSCRIPTION_SETTINGS_API + email, { old_sum: currentSum, new_sum: sum, new_page: newPage }, cb)
  },
  startApplePaySession (data, cb) {
    request('POST', APPLEPAY_API, data, cb)
  },
  getConfig (cb) {
    request('GET', CONFIG_API, {}, cb)
  },
  getQrStatus (donationId, cb) {
    request('GET', `${QR_STATUS_API}?donationId=${donationId}`, {}, cb)
  }
}
